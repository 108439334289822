import { Match, onMount, Show, Switch } from "solid-js";
import { cardGenerationFailed } from "~/assets/assets";
import { RNREvent, rnrEventManager } from "~/data/events";
import { openIntercom } from "~/utils/third_party/intercom";

type CardFailedProps = {
  isDeclined?: boolean;
  showContactSupport?: boolean;
  coinOnly: boolean;
  orderId?: string;
};

export function CardFailed(props: CardFailedProps) {
  onMount(() => {
    rnrEventManager.sendEvent(RNREvent.ORDER_FAILED, {
      orderId: props.orderId,
    });
  });

  return (
    <div class="fixed left-0 top-0 flex h-full w-full items-center justify-center">
      <div class="flex flex-col items-center gap-6 px-8">
        <div class="flex w-fit flex-col items-center gap-3">
          <div class="content-center">
            <img
              src={cardGenerationFailed}
              class="h-[120px] w-[150px]"
              alt="card generation failed"
            />
          </div>
          <Switch>
            <Match when={props.isDeclined}>
              <div class="text-h5 uppercase text-errorDark">
                Transaction Declined
              </div>
              <div class="h-[6px] w-[36px] rounded bg-gray-100"></div>
              <div class="flex flex-col gap-1 text-center text-h3 text-textDark">
                <div class="text-h5 text-textDark">
                  Please retry or contact support if the issue continues
                </div>
                <div class="text-normal text-textNormal">
                  Any {props.coinOnly ? "coin" : "money"} deducted will be
                  refunded to you in 3-5 business days
                </div>
              </div>
            </Match>
            <Match when="true">
              <div class="text-h5 text-errorDark">Card failed to generate</div>
              <div class="h-1 w-14 rounded bg-gray-100"></div>
              <div class="text-center text-h3 text-textDark">
                Any {props.coinOnly ? "coin" : "money"} deducted will be
                refunded to you in 3-5 business days
              </div>
            </Match>
          </Switch>
        </div>

        <Show when={props.showContactSupport}>
          <div
            class="w-fit cursor-pointer rounded-[30px] border-[1px] border-solid border-[#DDD] px-8 py-2 text-mediumBold text-textDark hover:border-[#212427]"
            style={{
              "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.04);",
            }}
            onClick={openIntercom}
          >
            Contact support
          </div>
        </Show>
      </div>
    </div>
  );
}
