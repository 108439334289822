import {
  cache,
  createAsync,
  useIsRouting,
  useLocation,
  useSearchParams,
} from "@solidjs/router";
import {
  Show,
  Accessor,
  ErrorBoundary,
  Match,
  Suspense,
  Switch,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
  createMemo,
} from "solid-js";

import { Divider } from "~/widgets/divider";
import { OrderDetails } from "~/server/types/order";
import {
  Header,
  isFreshLogin,
  setIsFreshLogin,
} from "~/components/brand_l2/header";
import { CardFailed } from "~/components/brand_order/card_failed";
import {
  CardReadySectionHeader,
  CardReadySectionVoucher,
} from "~/components/brand_order/card_ready_section";
import { CardsGettingReady } from "~/components/brand_order/cards_getting_ready";
import {
  getCoinsSummary,
  getStoreOrderDetails,
} from "~/server/apis/client_apis";
import {
  BrandOrderRouteData,
  getBrandOrderRouteData,
} from "~/server/data/brand_order_route_data";
import { HubbleError } from "~/components/error";
import { SdkTopRightCloseButton } from "~/components/sdk_tr_close_button";
import { openIntercom } from "~/utils/third_party/intercom";
import { BrandingMinimal } from "~/widgets/branding";
import { RNREvent, rnrEventManager } from "~/data/events";
import { getClientIdFromCookie } from "~/utils/common";
import { captureErrorInSentry } from "~/utils/third_party/sentry";
import { ThreeDotLoader } from "~/widgets/button";

function ContactSupport() {
  return (
    <div class="inline-flex h-[18px]  items-center justify-start gap-2">
      <div class="font-['Inter'] text-[13px] font-normal leading-[18.20px] text-neutral-700">
        Facing issues?
      </div>
      <div
        class="cursor-pointer font-['Inter'] text-[13px] font-normal leading-[18.20px] text-neutral-700 underline"
        onClick={openIntercom}
      >
        Contact support
      </div>
    </div>
  );
}

function SentOverWhatsappTip() {
  return (
    <div class="flex flex-row items-center gap-2 text-medium text-textDark">
      <span class="h-10 w-10 shrink-0 grow-0">
        <img
          src="https://gullak-assets.s3.ap-south-1.amazonaws.com/icons/image%20658.png"
          class="h-10 w-10"
          alt="Whatsapp icon"
        />
      </span>
      <span>Gift card details have been sent to you over WhatsApp as well</span>
    </div>
  );
}

type CardReadyProps = {
  order: OrderDetails;
  showWhatsappTip: boolean;
  showContactSupport: boolean;
  showHubbleLogoOnCard: boolean;
  showBranding?: boolean;
};

function CardReady(props: CardReadyProps) {
  onMount(() => {
    rnrEventManager.sendEvent(RNREvent.ORDER_SUCCEEDED, {
      orderId: props.order.id,
    });
  });

  return (
    <div class="m-auto mt-5 flex justify-center pb-10 lg:mt-[48px] lg:flex lg:w-[844px] lg:flex-row lg:justify-between lg:pb-0">
      <div class="flex w-full flex-col gap-4 px-3 lg:w-[300px]">
        <CardReadySectionHeader amount={props.order.amount} />
        <div class="lg:hidden">
          <Voucher
            order={props.order}
            showHubbleLogoOnCard={props.showHubbleLogoOnCard}
          />
        </div>
        <Divider />
        <Show when={props.showWhatsappTip}>
          <SentOverWhatsappTip />
          <Divider />
        </Show>
        <SupportAndBranding />
      </div>

      <div class="hidden lg:block lg:w-[464px]">
        <Voucher
          order={props.order}
          showHubbleLogoOnCard={props.showHubbleLogoOnCard}
        />
      </div>
    </div>
  );

  function SupportAndBranding() {
    return (
      <div>
        <div class="flex flex-row flex-wrap-reverse items-baseline justify-between lg:hidden">
          <Show when={props.showBranding}>
            <div class="flex grow flex-row 	">
              <BrandingMinimal />
            </div>
          </Show>
          <Show when={props.showContactSupport}>
            <ContactSupport />
          </Show>
        </div>
        <div class="hidden flex-col justify-start gap-4 lg:flex">
          <Show when={props.showContactSupport}>
            <ContactSupport />
          </Show>
          <Show when={props.showBranding}>
            <div class="flex flex-row ">
              <BrandingMinimal />
            </div>
          </Show>
        </div>
      </div>
    );
  }
}

type VoucherProps = {
  order: OrderDetails;
  showHubbleLogoOnCard: boolean;
};

function Voucher(props: VoucherProps) {
  return (
    <CardReadySectionVoucher
      brandId={props.order.orderProductDetails.productId}
      brandName={props.order.orderProductDetails.voucherProductTitle!}
      amount={props.order.amount}
      voucherRedemptionType={
        props.order.orderProductDetails.vouchers![0].voucherMetadata
          .voucherRedemptionType ?? "CARD_AND_PIN_NO_SECURED"
      }
      cardNumber={props.order.orderProductDetails.vouchers![0].cardNumber}
      pin={props.order.orderProductDetails.vouchers![0].cardPin}
      deeplink={
        props.order.orderProductDetails.vouchers![0].voucherMetadata
          .addVoucherBrandPageUrl!
      }
      tncResId={
        props.order.orderProductDetails.vouchers![0].voucherMetadata.tncResId
      }
      date={
        props.order.orderProductDetails.vouchers![0].generatedDateTime ??
        props.order.date
      }
      isAssured={
        props.order.orderProductDetails.vouchers![0].voucherMetadata
          .isVoucherAssured ?? false
      }
      plainLogoUrl={
        props.order.orderProductDetails.vouchers![0].voucherMetadata
          .plainLogoUrl!
      }
      cardBackgroundColor={
        props.order.orderProductDetails.vouchers![0].voucherMetadata
          .cardBackgroundColor!
      }
      showHubbleLogoOnCard={props.showHubbleLogoOnCard}
      shareText={
        props.order.orderProductDetails.vouchers![0].voucherMetadata.shareText!
      }
    />
  );
}

const getData = cache(getBrandOrderRouteData, "brand-order");

export type OrderRouteState = {
  headerLogo: string;
  isSDK: boolean;
  isLoggedIn: boolean;
};

export default function VoucherGenerateRoute() {
  const isRouting = useIsRouting();
  const [searchParams] = useSearchParams();
  const location = useLocation<OrderRouteState>();

  const paymentFailureReason = searchParams.paymentFailureReason;
  const orderId = searchParams.orderId!;

  const routeData: Accessor<BrandOrderRouteData | undefined> = createAsync(() =>
    getData()
  );

  const [order, setOrder] = createSignal<OrderDetails | undefined>();
  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(undefined);
  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);

  let orderPollInterval: NodeJS.Timeout | undefined;

  const setupOrderPolling = async (orderId: string) => {
    const updatedOrder = await getStoreOrderDetails(orderId);
    setOrder(updatedOrder);

    orderPollInterval = setInterval(async () => {
      const updatedOrder = await getStoreOrderDetails(orderId);
      setOrder(updatedOrder);
    }, 5000);
  };

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
  });

  onMount(async () => {
    if (!paymentFailureReason) {
      await setupOrderPolling(orderId);
    }
  });

  onCleanup(() => {
    if (orderPollInterval) clearInterval(orderPollInterval);
  });

  createEffect(() => {
    if (!order()) return;

    if (
      order()?.status != "INITIALISED" &&
      order()?.status != null &&
      order()?.status != "PROCESSING"
    ) {
      clearInterval(orderPollInterval);
      console.log("voucher generated");
    }
  });

  function getMode() {
    return routeData()?.mode ?? "hubble";
  }

  const isLoggedIn = createMemo<boolean>(() => {
    return isFreshLogin() ?? location.state?.isLoggedIn ?? false;
  });

  createEffect(() => {
    setIsFreshLogin(routeData()?.isLoggedIn ?? false);
  });

  async function fetchLatestBalance() {
    const response = await getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      setRefreshLatestBalance(false);
    }
  });

  createEffect(async () => {
    if (isLoggedIn()) {
      await fetchLatestBalance();
    }
  });

  createEffect(async () => {
    if (order()?.status === "COMPLETED") {
      await fetchLatestBalance();
    }
  });

  const getCoins = createMemo<number>(() => {
    return (
      latestCoinsBalance() ?? routeData()?.coinsSummary?.totalAvailable ?? 0
    );
  });

  console.log(303, getCoins());

  return (
    <ErrorBoundary
      fallback={(err) => {
        captureErrorInSentry(err);
        return <HubbleError errorMessage={err.message} />;
      }}
    >
      <Suspense>
        <div class="h-full">
          <Show
            when={
              routeData() && !(location.state?.isSDK ?? getMode() === "sdk")
            }
            fallback={
              <Show when={location.state?.isSDK ?? getMode() === "sdk"}>
                <SdkTopRightCloseButton />
              </Show>
            }
          >
            <Header
              headerImageUrl={
                location.state?.headerLogo ??
                routeData()?.clientConfig?.clientLogoUrl
              }
              mode={getMode()}
              coins={getCoins}
              showActions={
                !(location.state?.isSDK ?? getMode() === "sdk" ?? false)
              }
              isLoggedIn={isLoggedIn}
              setRefreshCoins={setRefreshLatestBalance}
            />
          </Show>
          <Switch>
            <Match when={isRouting()}>
              <div class="flex h-full flex-col items-center justify-center">
                <ThreeDotLoader color="#999" />
              </div>
            </Match>
            <Match when={order()?.status === "COMPLETED"}>
              <CardReady
                order={order()!}
                showContactSupport={
                  !(location.state?.isSDK ?? getMode() === "sdk")
                }
                showWhatsappTip={
                  !(location.state?.isSDK ?? getMode() === "sdk")
                }
                showHubbleLogoOnCard={
                  !(location.state?.isSDK ?? getMode() === "sdk")
                }
                showBranding={getMode() == "rnr"}
              ></CardReady>
            </Match>
            <Match
              when={paymentFailureReason?.includes(
                "has been used by more than"
              )}
            >
              <CardFailed
                orderId={order()?.id}
                isDeclined={true}
                showContactSupport={true}
                coinOnly={routeData()?.mode === "rnr"}
              />
            </Match>
            <Match when={order()?.status === "FAILED" || paymentFailureReason}>
              <CardFailed
                orderId={order()?.id}
                showContactSupport={
                  !(location.state?.isSDK ?? getMode() === "sdk")
                }
                coinOnly={routeData()?.mode === "rnr"}
              />
            </Match>
            <Match when={true}>
              <CardsGettingReady />
            </Match>
          </Switch>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
}
